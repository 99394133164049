import { axiosInsWithAuth, axiosIns } from '@/libs/axios'

const cmnApi = {
  // 관리자 정보 조회
  fetchMngrInf(payload) {
    return axiosInsWithAuth.get('/apicomn/findLoginMngrInfo', {
      params: {
        mngrId: payload.mngrId
      }
    })
  },
  // 네비게이션 메뉴 조회
  fetchNavMenu() {
    return axiosInsWithAuth.get('/apicomn/findNavMenuList')
  },
  // 코드상세 목록 조회
  fetchCdDtlList(payload) {
    return axiosInsWithAuth.get('/apicomn/findCodeDetailList', {
      params: {
        cdGrpId: payload.cdGrpId
      }
    })
  },
  // 지역본부 목록 조회
  fetchGrpList(payload) {
    return axiosInsWithAuth.get('/apicomn/findGroupList', {
      params: {
        ...payload
      }
    })
  },
  // 단지 목록 조회
  fetchSbdNmList(payload) {
    return axiosInsWithAuth.get('/apicomn/findSubdivisionList', {
      params: {
        grpSn: payload.grpSn,
        hnetFrmSn: payload.hnetFrmSn,
      }  
    })
  },
  // 동 목록 조회
  fetchDngDsList(payload) {
    return axiosInsWithAuth.get('/apicomn/findDngList', {
      params: {
        sbdId: payload.sbdId
      }  
    })
  },
  // 호 목록 조회
  fetchHoDsList(payload) {
    return axiosInsWithAuth.get('/apicomn/findHoList', {
      params: {
        sbdId: payload.sbdId,
        dngDs: payload.dngDs
      }  
    })
  },
  // 세대 목록 조회
  fetchHshList(payload) {
    return axiosInsWithAuth.get('/apicomn/findHshList', {
      params: {
        sbdId: payload.sbdId,
      }  
    })
  },
  // 파일 다운로드
  fetchFile(payload) {
    return axiosInsWithAuth.post('/apicomn/fileDownload',payload , { responseType: 'blob' });
  },
  // 단지 목록 조회(회원가입용)
  fetchSbdNmListForJoin(payload) {
    return axiosInsWithAuth.get('/cert/findSubdivisionListForJoin');
  },
  fetchRsdNcList(payload) {
    return axiosIns.get('/cert/findResidentsNoticeList', {
      params: {
        pageSize: payload.pageSize,
        pageNum: payload.pageNum,
        grpSn: payload.grpSn,
        sbdId: payload.sbdId,
        anncFctsDsCd: payload.anncFctsDsCd
      }  
    });
  },
  fetchRsdNc(payload) {
    return axiosIns.get('/cert/findResidentsNoticeDetailInfo', {
      params: {
        anncFctsSn: payload.anncFctsSn
      }
    });
  },
  async inputSystemRecord(payload) {
    return axiosInsWithAuth.post('/user/inputSystemRecord', payload);
  },
  async inputUserRecord(payload) {
    return axiosInsWithAuth.post('/user/inputUserRecord', payload);
  },
  // 이벤트 코드 목록 조회
  async fetchEvnCdList() {
    return axiosInsWithAuth.get('/event/findEventCdList');
  },
  // 건물 아이디 목록 조회(동 맵핑용)
  async fetchBldIdList(payload) {
    return axiosInsWithAuth.get('/dsbd/findBldList', {
      params: {
        sbdId: payload.sbdId
      }
    })
  },
  // 홈넷사 목록 조회
  async fetchHnetList() {
    return axiosInsWithAuth.get('/complex/findFrmList');
  },
  // 공통 코드 전체 조회
  async fetchCmnCd() {
    return axiosInsWithAuth.get('/apicomn/findAllCodeDetailList');
  }
}

export default cmnApi;
